/**
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import { FeatureFlag, isFeatureEnabled } from '@superset-ui/core';
import React, { lazy } from 'react';

// not lazy loaded since this is the home page.
import Home from 'src/pages/Home';

const ExcelPlugin = lazy(
  () => import(/* webpackChunkName: "Monitor" */ 'src/pages/ExcelPlugin'),
);
const Monitor = lazy(
  () => import(/* webpackChunkName: "Monitor" */ 'src/pages/Monitor'),
);
const ChartCreation = lazy(
  () =>
    import(/* webpackChunkName: "ChartCreation" */ 'src/pages/ChartCreation'),
);
const AiAnalyse = lazy(
  () =>
    import(/* webpackChunkName: "AiAnalyse" */ 'src/pages/AiAnalyse'),
);
const AnnotationLayerList = lazy(
  () =>
    import(
      /* webpackChunkName: "AnnotationLayerList" */ 'src/pages/AnnotationLayerList'
    ),
);

const AlertReportList = lazy(
  () =>
    import(
      /* webpackChunkName: "AlertReportList" */ 'src/pages/AlertReportList'
    ),
);

const AnnotationList = lazy(
  () =>
    import(/* webpackChunkName: "AnnotationList" */ 'src/pages/AnnotationList'),
);

const ChartList = lazy(
  () => import(/* webpackChunkName: "ChartList" */ 'src/pages/ChartList'),
);

const CssTemplateList = lazy(
  () =>
    import(
      /* webpackChunkName: "CssTemplateList" */ 'src/pages/CssTemplateList'
    ),
);

const DashboardList = lazy(
  () =>
    import(/* webpackChunkName: "DashboardList" */ 'src/pages/DashboardList'),
);

const Dashboard = lazy(
  () => import(/* webpackChunkName: "Dashboard" */ 'src/pages/Dashboard'),
);

const DatabaseList = lazy(
  () => import(/* webpackChunkName: "DatabaseList" */ 'src/pages/DatabaseList'),
);

const DatasetList = lazy(
  () => import(/* webpackChunkName: "DatasetList" */ 'src/pages/DatasetList'),
);

const DatasetCreation = lazy(
  () =>
    import(
      /* webpackChunkName: "DatasetCreation" */ 'src/pages/DatasetCreation'
    ),
);

const CustomAnalysisGuide = lazy(
  () =>
    import(
      /* webpackChunkName: "CustomAnalysisGuide" */ 'src/features/home/CustomAnalysisGuide'
    ),
);

const ExecutionLogList = lazy(
  () =>
    import(
      /* webpackChunkName: "ExecutionLogList" */ 'src/pages/ExecutionLogList'
    ),
);

const Chart = lazy(
  () => import(/* webpackChunkName: "Chart" */ 'src/pages/Chart'),
);

const QueryHistoryList = lazy(
  () =>
    import(
      /* webpackChunkName: "QueryHistoryList" */ 'src/pages/QueryHistoryList'
    ),
);

const SavedQueryList = lazy(
  () =>
    import(/* webpackChunkName: "SavedQueryList" */ 'src/pages/SavedQueryList'),
);

const SqlLab = lazy(
  () => import(/* webpackChunkName: "SqlLab" */ 'src/pages/SqlLab'),
);

const AllEntities = lazy(
  () => import(/* webpackChunkName: "AllEntities" */ 'src/pages/AllEntities'),
);

const Tags = lazy(
  () => import(/* webpackChunkName: "Tags" */ 'src/pages/Tags'),
);

const RowLevelSecurityList = lazy(
  () =>
    import(
      /* webpackChunkName: "RowLevelSecurityList" */ 'src/pages/RowLevelSecurityList'
    ),
);

const Login = lazy(
  () =>
    import(/* webpackChunkName: "Login" */ 'src/pages/Account/Login'),
);

const Register = lazy(
  () =>
    import(/* webpackChunkName: "Register" */ 'src/pages/Account/Register'),
);

const ErrorPage = lazy(
  () =>
    import(/* webpackChunkName: "ErrorPage" */ 'src/pages/ErrorPage'),
);

const UserInfo = lazy(
  () =>
    import(/* webpackChunkName: "UserInfo" */ 'src/pages/User'),
);
const Profile = lazy(
  () => import(/* webpackChunkName: "Profile" */ 'src/pages/Profile'),
);

const Authorization = lazy(
  () =>
    import(/* webpackChunkName: "Authorization" */ 'src/pages/Authorization'),
);

const FbAuthPage = lazy(
  () =>
    import(/* webpackChunkName: "FbAuthPage" */ 'src/pages/FbAuthPage'),
);

const Website = lazy(
  () =>
    import(/* webpackChunkName: "Website" */ 'src/pages/Website'),
);

const Inspection = lazy(
  () => 
    import(/* webpackChunkName: "Inspection" */ 'src/pages/Inspection'),
);
const Reports = lazy(
  () => 
    import(/* webpackChunkName: "Reports" */ 'src/pages/Reports'),
);
const Adtopic = lazy(
  () => 
    import(/* webpackChunkName: "Adtopic" */ 'src/pages/Adtopic'),
);
const Pricing = lazy(
  () => 
    import(/* webpackChunkName: "Pricing" */ 'src/pages/Pricing'),
);
const FbAdvert = lazy(
  () => 
    import(/* webpackChunkName: "Pricing" */ 'src/pages/FbAdvert'),
);


type Routes = {
  path: string;
  Component: React.ComponentType;
  Fallback?: React.ComponentType;
  props?: React.ComponentProps<any>;
}[];

export const routes: Routes = [
  {
    path: '/superset/welcome/',
    Component: Home,
  },
  {
    path: '/home/',
    Component: Website,
  },
  {
    path: '/user/info/',
    Component: UserInfo,
  },
  {
    path: '/dashboard/list/',
    Component: DashboardList,
  },
  {
    path: '/superset/dashboard/:idOrSlug/',
    Component: Dashboard,
  },
  {
    path: '/chart/add',
    Component: ChartCreation,
  },
  {
    path: '/chat/',
    Component: AiAnalyse,
  },
  {
    path: '/chart/list/',
    Component: ChartList,
  },
  {
    path: '/tablemodelview/list/',
    Component: DatasetList,
  },
  {
    path: '/databaseview/list/',
    Component: DatabaseList,
  },
  {
    path: '/savedqueryview/list/',
    Component: SavedQueryList,
  },
  {
    path: '/csstemplatemodelview/list/',
    Component: CssTemplateList,
  },
  {
    path: '/annotationlayer/list/',
    Component: AnnotationLayerList,
  },
  {
    path: '/annotationlayer/:annotationLayerId/annotation/',
    Component: AnnotationList,
  },
  {
    path: '/sqllab/history/',
    Component: QueryHistoryList,
  },
  {
    path: '/alert/list/',
    Component: AlertReportList,
  },
  {
    path: '/report/list/',
    Component: AlertReportList,
    props: {
      isReportEnabled: true,
    },
  },
  {
    path: '/alert/:alertId/log/',
    Component: ExecutionLogList,
  },
  {
    path: '/report/:alertId/log/',
    Component: ExecutionLogList,
    props: {
      isReportEnabled: true,
    },
  },
  {
    path: '/explore/',
    Component: Chart,
  },
  {
    path: '/superset/explore/p',
    Component: Chart,
  },
  {
    path: '/dataset/add/',
    Component: DatasetCreation,
  },
  {
    path: '/dataset/:datasetId',
    Component: DatasetCreation,
  },
  {
    path: '/rowlevelsecurity/list',
    Component: RowLevelSecurityList,
  },
  {
    path: '/login',
    Component: Login,
  },
  {
    path: '/register',
    Component: Register,
  },
  // {
  //   path: '/error',
  //   Component: ErrorPage,
  // }
  {
    path: '/profile',
    Component: Profile,
  },
  {
    path: '/sqllab/',
    Component: SqlLab,
  },
  {
    path: '/fbAuth',
    Component: FbAuthPage,
  },
  {
    path: '/auth/',
    Component: Authorization,
  },
  {
    path: '/util/excel',
    Component: ExcelPlugin,
  },
  {
    path: '/util/monitor',
    Component: Monitor
  },
  {
    path: '/inspection',
    Component: Inspection
  },
  {
    path: '/reports',
    Component: Reports
  },
  {
    path: '/adtopic',
    Component: Adtopic
  },
  {
    path: '/pricing/',
    Component: Pricing
  },
  {
    path: '/customAnalysisGuide',
    Component: CustomAnalysisGuide
  },
  {
    path: '/fb',
    Component: FbAdvert,
  }
];

if (isFeatureEnabled(FeatureFlag.TAGGING_SYSTEM)) {
  routes.push({
    path: '/superset/all_entities/',
    Component: AllEntities,
  });
  routes.push({
    path: '/superset/tags/',
    Component: Tags,
  });
}

const frontEndRoutes = routes
  .map(r => r.path)
  .reduce(
    (acc, curr) => ({
      ...acc,
      [curr]: true,
    }),
    {},
  );

export function isFrontendRoute(path?: string) {
  if (path) {
    const basePath = path.split(/[?#]/)[0]; // strip out query params and link bookmarks
    
    return !!frontEndRoutes[basePath];
  }
  return false;
}
