
import React, { memo } from 'react';
import { useHistory } from 'react-router-dom';
import ConfirmButton from 'src/pages/Adtopic/component/ConfirmButton';
import styles from './index.module.less';

interface BuyModalProps {
  isExpiredAt?: string;
  children?: React.ReactNode;
}

const BuyModal: React.FC<BuyModalProps> = (props) => {
  const { children, isExpiredAt } = props;
  const history = useHistory();

  const goToBuy = () => {
    history.push('/pricing/');
  };

  return (
    <div className={styles.maskContent}>
      <div className={styles.maskWarpper} />
      <div className={styles.maskContainer}>
        <div className={styles.content}>
          <p className={styles.title}>
            {isExpiredAt ? '高级版账号已过期，请续费' : '免费版账号暂无权限使用，'}
          </p>
          <p className={styles.title}>
            {isExpiredAt ? `过期时间：${isExpiredAt}` : '请升级至高级版账号。'}
          </p>
        </div>
        <div className={styles.bottom}>
          <ConfirmButton
            onClick={goToBuy}
            className={styles.goBuy}
            text="前往购买"
          />
        </div>
      </div>
      {children}
    </div>
  );
};

export default memo(BuyModal);
